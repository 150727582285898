<template>
    <div class="tarot">
        <baydin-navbar title="Tarot" />
        <div class="container">
            <div class="row form-group">
                <label for="date" class="col-3 my-auto">Birthday:</label>
                <div class="col-9 d-flex">
                    <input
                        v-model="birthday"
                        type="date"
                        class="form-control"
                    />
                </div>
            </div>

            <div class="text-center mt-3 mb-3">
                <button class="btn btn-primary" @click="getAnswer">
                    Get Card
                </button>
            </div>

            <div v-if="tarot && tarot.name">
                <img :src="tarot.img" alt="image" width="100" height="100" />
                <p>{{ tarot.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import BaydinNavbar from '@/components/reusable/BaydinNavbar.vue';

export default {
    components: {
        'baydin-navbar': BaydinNavbar,
    },

    data() {
        return {
            birthday: '',
            tarot: {
                img: '',
                name: '',
            },
        };
    },

    methods: {
        getAnswer() {
            var baydin_token = localStorage.getItem('baydin_token');

            const date = new Date(this.birthday);
            let formatted_date =
                String(date.getDate()).padStart(2, '0') +
                '-' +
                String(date.getMonth() + 1).padStart(2, '0') +
                '-' +
                date.getFullYear();
            console.log(formatted_date, 'format');
            var formdata = new FormData();
            formdata.append('birth_date', formatted_date);

            var requestOptions = {
                method: 'GET',
                body: formdata,
                redirect: 'follow',
                headers: {
                    Authorization: `Bearer ${baydin_token}`,
                },
            };

            fetch(
                'https://ibaydin.pyaesoneshin.com/ibaydin/public/api/tarot/answer',
                requestOptions,
            )
                .then(response => response.json())
                .then(result => {
                    console.log(result);
                    this.tarot = {
                        img: result.data.image,
                        name: result.data.name,
                    };
                })
                .catch(error => console.log('error', error));
        },
    },
};
</script>
